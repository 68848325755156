import { AppError } from './app-error';

export class InvalidEmailError extends AppError {
    authError = {
        code: 'auth/invalid-email',
        message: 'The email format is not correct.'
    };
    constructor(public originalError?: any) {
        super(originalError);
        this.originalError = this.authError;

    }

}